<template>
  <div class="main">
    <div class="outer">
      <div class="contractSelect">
        <div class="select">
          <div>
            <span class="select-label">合同选择：</span>
            <el-select
              v-model="contract"
              @change="contractO"
              placeholder="请选择合同"
              size="small"
              popper-class="ds-cen-popper-class"
            >
              <el-option
                v-for="item in contractOptions"
                :key="item.id"
                :label="item.contractName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div style="margin-top: 16px">
            <span class="select-label">合同类型：</span>
            <el-select
              @change="contractqw"
              v-model="contractType"
              placeholder="请选择合同"
              size="small"
              popper-class="ds-cen-popper-class"
            >
              <el-option
                v-for="item in contractTypeOptions"
                :key="item.id"
                :label="item.contractBusinessMark"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="pie-chart">
          <div class="pie-chart-label">
            <span class="percent">{{ progress }}%</span>
            <span class="schedule">完成进度</span>
          </div>
          <div id="pie"></div>
        </div>
      </div>
      <div class="basicInfo">
        <div class="basicInfo-title">
          基础信息
        </div>
        <ul>
          <li>
            <span class="basicInfo-label">起止时间：</span>
            <span class="basicInfo-content">{{ startTime }}-{{ endTime }}</span>
          </li>
          <li>
            <span class="basicInfo-label">补助总金额：</span>
            <span class="basicInfo-content">{{ totalAmount }}</span>
          </li>
          <li>
            <span class="basicInfo-label">人均补助金额：</span>
            <span class="basicInfo-content">{{ subsidyAmount }}</span>
          </li>
          <li>
            <span class="basicInfo-label">服务人数：</span>
            <span class="basicInfo-content">{{ serviceNumberPeople }}</span>
          </li>
        </ul>
      </div>
      <div class="completeInfo">
        <div class="completeInfo-title">
          完成信息
        </div>
        <div class="completeInfo-content">
          <div>
            <span style="color:#FF8529">{{ nums }}</span>
            <span>已服务人数</span>
          </div>
          <div style="margin-left: 10px;margin-right:10px">
            <span>{{ remainingAmount}}</span>
            <span>剩余补助金额</span>
          </div>
          <div>
            <span>{{ additionalAmount}}</span>
            <span>优惠总金额</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getContractByIns,getCostatinums } from '@/api/elderlyRecords/contract.js'
export default {
  data() {
    return {
      contractOptions: [],
      contractTypeOptions: [],
      contractType: '',
      contract: '',
      insNo: '', //机构号
        progress: '',
        startTime: '',
        endTime: '',
        totalAmount:'',
        subsidyAmount: '',
        serviceNumberPeople: '',
        remainingAmount: '',
        additionalAmount: '',
        nums:''
    }
  },
  created() {
    this.insNo = this.$route.query.insNo
  },
  mounted() {
    this.getAreacontract()
  },
  methods: {
    getAreacontract() {
      getContractByIns(this.insNo).then((res) => {
        this.contractOptions = res.data
        this.contract = this.contractOptions[0].id
        this.contractTypeOptions = this.contractOptions[0].contracts
        this.contractType = this.contractTypeOptions[0].id
          this.progress = this.contractTypeOptions[0].progress
          this.startTime=this.contractTypeOptions[0].startTime
          this.endTime=this.contractTypeOptions[0].endTime
          this.totalAmount=this.contractTypeOptions[0].totalAmount
          this.subsidyAmount=this.contractTypeOptions[0].subsidyAmount
          this.serviceNumberPeople=this.contractTypeOptions[0].serviceNumberPeople
          this.remainingAmount=this.contractTypeOptions[0].remainingAmount
          this.additionalAmount = this.contractTypeOptions[0].additionalAmount
          this.getCostatinums(this.contractOptions[0].contracts[0].globalId)
        this.init()
      })
    },
    contractO(e) {
      this.contractOptions.map((item) => {
        if (e == item.id) {
          this.contractTypeOptions = item.contracts
          this.contractType = this.contractTypeOptions[0].id
            this.progress = this.contractTypeOptions[0].progress
            this.startTime=this.contractTypeOptions[0].startTime
          this.endTime=this.contractTypeOptions[0].endTime
          this.totalAmount=this.contractTypeOptions[0].totalAmount
          this.subsidyAmount=this.contractTypeOptions[0].subsidyAmount
          this.serviceNumberPeople=this.contractTypeOptions[0].serviceNumberPeople
          this.remainingAmount=this.contractTypeOptions[0].remainingAmount
            this.additionalAmount = this.contractTypeOptions[0].additionalAmount
          this.getCostatinums(this.contractTypeOptions[0].globalId)
          this.init()
        }
      })
    },
    contractqw(e) {
      this.contractTypeOptions.map((item) => {
        if (e == item.id) {
            this.progress = item.progress
            this.startTime=item.startTime
          this.endTime=item.endTime
          this.totalAmount=item.totalAmount
          this.subsidyAmount=item.subsidyAmount
          this.serviceNumberPeople=item.serviceNumberPeople
          this.remainingAmount=item.remainingAmount
            this.additionalAmount = item.additionalAmount
            this.getCostatinums(item.globalId)
          this.init()
        }
      })
      },
      getCostatinums (data) {
          getCostatinums(data).then(res => {
            this.nums=res.data
        })
      },
    init() {
      this.$nextTick(() => {
        let point = Number(this.progress)
        console.log(point, 555)
        let option = {
          series: [
            {
              name: 'circle',
              type: 'pie',
              clockWise: true,
              radius: ['60%', '100%'],
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                },
              },
              hoverAnimation: false,
              data: [
                {
                  name: '剩余',
                  value: 100-point,
                  itemStyle: {
                    normal: {
                      color: '#091A54',
                    },
                  },
                },
                {
                  value: point,
                  name: '占比',
                  itemStyle: {
                    normal: {
                      color: '#25D64D',
                    },
                  },
                },
              ],
            },
          ],
        }
        let myChart = echarts.init(document.querySelector('#pie'))
        myChart.setOption(option)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  background: url('~@/assets/images/twoThreeImgs/left/contractMessage.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  height: 20.91666666666rem;
  width: 100%;
  margin-top: 0.41rem;
  overflow: hidden;

  .outer {
    margin-top: 1.83rem;
    padding: 0 0.41rem 0.41rem;
  }
  .contractSelect {
    height: 4.75rem;
    background: rgba(5, 13, 38, 0.31);
    border-radius: 2px;
    border: 1px solid #213060;
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep .el-input__inner {
      background: #36447d;
      border-color: #5869ab;
      color: #8EB8E6;
    }
    .select-label {
        font-size: 0.5833rem;
font-weight: 400;
color: #B4C6FE;
opacity: 0.6;
    }
    .pie-chart {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      margin-left: 1.583333rem;
      .pie-chart-label {
        display: flex;
        flex-direction: column;
        height: 70%;
        justify-content: space-evenly;
        .percent {
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #25d64d;
          font-size: 1.25rem;
        }
        .schedule {
          font-size: 0.583rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8eb8e6;
        }
      }
      #pie {
        height: 3.5rem;
        width: 3.5rem;
        margin-left: 0.8rem;
      }
    }
  }
  .basicInfo {
    height: 7.5rem;
    background: rgba(5, 13, 38, 0.31);
    border-radius: 2px;
    border: 1px solid #213060;
    margin-top: 4px;
    padding-left: 0.66666rem;
    .basicInfo-title {
      margin-top: 0.66666rem;
      font-size: 0.66666rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #b4c6fe;
    }
    ul {
      li {
        margin-top: 0.5rem;
        .basicInfo-label {
          display: inline-block;
          width: 4.16rem;
          text-align: right;
          font-size: 0.5833333rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #5d6a9a;
        }
        .basicInfo-content {
          font-size: 0.5833333rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8eb8e6;
        }
      }
    }
  }
  .completeInfo {
    height: 5.9rem;
    background: rgba(5, 13, 38, 0.31);
    border-radius: 2px;
    border: 1px solid #213060;
    margin-top: 4px;
    padding-left: 0.66666rem;
    padding-right: 0.66666rem;
    .completeInfo-title {
      margin-top: 0.66666rem;
      font-size: 0.66666rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #b4c6fe;
    }

    .completeInfo-content {
      height: 3.4166666rem;
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      div {
        display: flex;
        background: rgba(5, 13, 38, 0.31);
        box-shadow: inset 0px 0px 13px 0px rgba(18, 42, 255, 0.5);
        border-radius: 2px;
        border: 1px solid #26439e;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
          &:nth-child(1) {
            font-size: 1.1rem;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #b4d3fe;
          }
          &:nth-child(2) {
            font-size: 0.583rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #5e7ca7;
          }
        }
      }
    }
  }
}

</style>
<style lang="scss">
.ds-cen-popper-class{
    position: absolute;
  z-index: 1001;

  border: 1px solid #193A6B;
  border-radius: 4px;

  background: #020c24;
  /* box-shadow: 0 2px 12px 0 rgba(0,0,0,.1); */
  box-sizing: border-box;
  margin: 5px 0;
  .el-popper[x-placement^='right'] .popper__arrow::after {
  bottom: -6px;
  left: 1px;
  border-right-color: #007aff;
  border-left-width: 0;
  border: 0; background: transparent;
}

.pophover {
  width: 11.66rem !important;
  position: absolute;
  /* background: #FFF; */
  min-width: 150px;
  /* border: 1px solid #EBEEF5; */
  padding: 0 0.33rem 0.33rem 0.33rem !important;
  z-index: 2000;
  line-height: 1.4;
  text-align: justify;
  font-size: 0.583rem;
  font-weight: 400;
  color: #8eb8e6;
  box-shadow: 0 2px 0.5rem 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  background: rgba(4, 9, 41, 0.95);
  box-shadow: inset 0px 0px 24px 0px rgba(0, 122, 255, 0.81);
  border-radius: 2px;
  border: 1px solid #0c87ff;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background: #172853;
}
.el-select-dropdown__item.selected {
    color: #8EB8E6;
    font-weight: 700;
}
.el-date-range-picker__content.is-left {
  border: none;
}
te-range-picker__header {
  color: #4dc2ff;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #ff9e14;
}

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background: rgba(255, 158, 20, 0.25);
  opacity: 0.6;
  color: #ffffff !important;
}
.el-popper .popper__arrow::after {
  content: '';
  border-width: 0px;
}
.el-popper .popper__arrow {
  border-width: 0px;
}
.el-picker-panel__icon-btn {
  color: #4dc2ff;
}
.el-date-table td.disabled div {
  background-color: #0d90ff;
  opacity: 0.5;
}
}
</style>
